
import { defineComponent, ref, unref, toRefs, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import { Rule, Rules } from 'async-validator';

import { useCampaign, useCreateCampaign, useUpdateCampaign } from '@/composables/api';
import { CreateCampaignData, CREATED_SUCCESSFULLY_TEXT, getChannels, MAX_PER_PAGE, ResponseError, UPDATED_SUCCESSFULLY_TEXT } from '@/services/api';
import { CampaignType, Channel, UtmFormatType } from '@/interfaces';
import { normalizeRequestData } from '@/utils/normalization';
import dayjs from 'dayjs';

const DEFAULT_FORM_VALUES: CreateCampaignData = {
  channelId: null,
  utmFormat: '',
  utmCampaign: '',
  utmSize: '',
  amount: '',
  startedAt: '',
  endedAt: ''
};

const REQUIRED_RULE: Rule = {
  required: true,
  message: '此為必填欄位'
};

const rules: Rules = {
  channelId: [REQUIRED_RULE],
  utmFormat: [REQUIRED_RULE],
  utmCampaign: [REQUIRED_RULE],
  amount: [REQUIRED_RULE],
  startedAt: [REQUIRED_RULE],
  endedAt: [REQUIRED_RULE]
};

const CAMPAIGN_TYPE_OPTIONS = Object
  .entries(CampaignType)
  .map(([label, value]) => ({ label, value }));

const UTM_TYPE_OPTIONS = Object
  .entries(UtmFormatType)
  .map(([label, value]) => ({ label, value }));

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const router = useRouter();
    const campaignId = useRoute().params.id as string;

    const channels = ref<Channel[]>([]);
    const isGetChannelsLoading = ref(true);

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreating, mutate: create } = useCreateCampaign();
    const { isLoading: isUpdating, mutate: update } = useUpdateCampaign();
    const { data, dataUpdatedAt } = useCampaign({ campaignId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    const defaultEndTime = ref(new Date(2000, 2, 1, 23, 59, 59));

    const fetchChannels = async() => {
      channels.value = (await getChannels({ query: { pageSize: MAX_PER_PAGE } })).data;
      isGetChannelsLoading.value = false;
    };

    onMounted(() => {
      fetchChannels();
    });

    const disabledStartDate = (datesOnCalendar: any) => {
      const today = new Date();
      const yesterday = new Date().setDate(today.getDate() - 1);
      return datesOnCalendar.getTime() < yesterday;
    };

    const disabledEndDate = (datesOnCalendar: any): Boolean => {
      const startDateTimestamp = dayjs(formValues.value.startedAt).valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const beforeStartDate = datesTimestamp < startDateTimestamp;

      return beforeStartDate;
    };

    const checkDateRange = () => {
      return (dayjs(formValues.value.endedAt).valueOf() < dayjs(formValues.value.startedAt).valueOf());
    };

    watch(formValues.value, () => {
      if (checkDateRange()) formValues.value.endedAt = '';
    });

    watch(dataUpdatedAt, () => {
      const campaignData = data.value.data;

      formValues.value = {
        ...campaignData,
        channelId: campaignData.channel.id
      };
    });

    const submitForm = () => {
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            const updateFormValues = {
              utmCampaign: formValues.value.utmCampaign,
              utmSize: formValues.value.utmSize,
              amount: formValues.value.amount
            };

            update(
              {
                campaignId,
                data: normalizeRequestData(updateFormValues)
              },
              {
                onSuccess() {
                  ElMessage.success(UPDATED_SUCCESSFULLY_TEXT);

                  router.go(-1);
                },
                onError(error: ResponseError) {
                  ElMessage.error(error.response?.data.message);
                }
              }
            );
            return;
          }

          create(
            { data: normalizeRequestData(formValues.value) },
            {
              onSuccess() {
                ElMessage.success(CREATED_SUCCESSFULLY_TEXT);

                formValues.value = DEFAULT_FORM_VALUES;

                router.go(-1);
              },

              onError(error: any) {
                ElMessage.error(error.response?.data.message);
              }
            }
          );
        }
      });
    };

    return {
      rules,
      formRef,
      channels,
      isCreating,
      formValues,
      isUpdating,
      defaultEndTime,
      UTM_TYPE_OPTIONS,
      isGetChannelsLoading,
      CAMPAIGN_TYPE_OPTIONS,
      disabledStartDate,
      disabledEndDate,
      submitForm
    };
  }
});
